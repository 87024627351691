import { useMutation, useQuery } from '@apollo/react-hooks';
import { NextPage } from 'next';
import Router from 'next/router';
import React, { useState, FormEvent, useEffect } from 'react';

import Alert from '../../components/Alert';
import { ConfirmPasswordForm } from '../../components/ConfirmPasswordForm';
import Container from '../../components/Container';
import ContainerResponsive from '../../components/ContainerResponsive';
import { CHANGE_PASSWORD } from '../../lib/mutations';
import { GET_ME } from '../../lib/queries';
import routes from '../../lib/routes';
import t from '../../lib/translations';

const ChangePassword: NextPage = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [changePasswordMutation, { data, loading, error }] = useMutation(CHANGE_PASSWORD);
  const { data: getMeData } = useQuery(GET_ME);
  const shouldChangePasswordAlert = getMeData?.me?.shouldChangePassword;

  const backToPreviousPage = (): void => {
    Router.push(routes.HOME);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
      await changePasswordMutation({
        variables: {
          changePasswordInput: {
            currentPassword: password,
            password: newPassword,
          },
        },
        refetchQueries: [{ query: GET_ME }],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (data?.changePassword) {
      setTimeout(backToPreviousPage, 1000);
    }
  }, [data]);

  const onChange = (e: any): any => {
    const value = e.target.value;
    const id = e.target.id;

    if (id === 'password') {
      setPassword(value);
    }
    if (id === 'newPassword') {
      setNewPassword(value);
    }
    if (id === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  // eslint-disable-next-line functional/no-let
  let content;
  if (!data?.changePassword) {
    content = (
      <div style={{ display: 'contents' }}>
        <p className="main-text">{t.CHANGE_PASSWORD}</p>
        <ConfirmPasswordForm
          password={password}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          onSubmit={onSubmit}
          onChangePassword={onChange}
          onChangeNewPassword={onChange}
          onChangeConfirmPassword={onChange}
          loading={loading}
          error={error}
        />
      </div>
    );
  } else {
    content = (
      <div className="login-body">
        <div className="login-content">
          <Alert testId="change-password-success" variant="success">
            {t.success.PASSWORD_CHANGED}
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <ContainerResponsive>
      <div className={`${data?.changePassword ? '' : 'div-background'} overflow-y-auto`}>
        {shouldChangePasswordAlert && (
          <div className="login-body pt-20 m-4">
            <div className="">
              <Alert testId="alertLogin" variant="danger">
                {t.info.CHANGE_DEFAULT_PASSWORD}
              </Alert>
            </div>
          </div>
        )}
        {content}
      </div>
    </ContainerResponsive>
  );
};
export default ChangePassword;

import React from 'react';

import helpers from '../lib/helpers';
import i18n from '../lib/i18n';
import { ErrorAlertContainerProps } from '../lib/types';
import Alert from './Alert';

const ErrorAlertContainer = ({
  error,
  onlyMessage = false,
}: ErrorAlertContainerProps): JSX.Element => (
  <>
    {helpers.parseError(error)?.graphQLErrors?.map((graphQLError: any, index: any): any => (
      <Alert testId="alertLogin" key={index} variant="warning">
        {i18n.t(`${onlyMessage ? '' : 'error.'}${graphQLError?.message?.toLowerCase()}`)}
      </Alert>
    ))}
  </>
);

export default ErrorAlertContainer;

import { useQuery } from '@apollo/react-hooks';
import { NextPage } from 'next';
import Head from 'next/head';
import Router from 'next/router';
import React from 'react';

import AuthRoute from '../components/AuthRoute';
import { Button } from '../components/Button';
import ContainerResponsive from '../components/ContainerResponsive';
import Loading from '../components/Loading';
import { GET_ME } from '../lib/queries';
import routes from '../lib/routes';
import t from '../lib/translations';
import ChangePassword from './settings/change-password';

const Home: NextPage = () => {
  const { data, loading } = useQuery(GET_ME);
  const shouldChangePassword = data?.me?.shouldChangePassword;

  if (loading) {
    return <Loading />;
  }

  function qrcode(): void {
    Router.push(routes.TRANSACTION);
  }

  function extract(): void {
    Router.push(routes.EXTRACT);
  }

  if (shouldChangePassword) {
    return <ChangePassword />;
  }

  return (
    <>
      <Head>
        <title data-testid="title">{t.HOME}</title>
        <link rel="icon" href="/logo.png" />
      </Head>

      <ContainerResponsive>
        <div className="flex flex-col m-auto justify-center items-center">
          <Button
            id="buttonQrcode"
            size="xl"
            className="rounded-full mx-1 text-2xl sm:text-3xl py-8 sm:mx-0 mb-12 w-64 min-w-max"
            color="gradientGreen"
            onClick={qrcode}>
            {t.GENERATE_QR_CODE}
          </Button>

          <Button
            id="buttonExtract"
            size="xl"
            className="rounded-full mx-1 text-2xl sm:text-3xl py-8 sm:mx-0 w-64 min-w-max"
            color="gradientGreen"
            onClick={extract}>
            {t.EXTRACT}
          </Button>
        </div>
      </ContainerResponsive>
    </>
  );
};

export default AuthRoute(Home);

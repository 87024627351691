import React from 'react';

const Loading = (): JSX.Element => {
  return (
    <div className="z-10 fixed inset-0 h-full w-full flex justify-center items-center loading">
      <div className="loader" />
    </div>
  );
};

export default Loading;
